import React from "react";
import { InstallLink, LinksContainer, Root } from "./styledComponents";

const PrivatePage = () => (
  <Root>
    <LinksContainer>
      <InstallLink
        href="itms-services://?action=download-manifest&url=https://sympl-apps.s3.eu-west-1.amazonaws.com/warehouse/manifest.plist"
        id="text"
      >
        App warehouse
      </InstallLink>
      <InstallLink
        href="itms-services://?action=download-manifest&url=https://sympl-apps.s3.eu-west-1.amazonaws.com/partner/manifest.plist"
        id="text"
      >
        App coursier
      </InstallLink>
    </LinksContainer>
  </Root>
);

export default PrivatePage;
