import React from "react";
import { Helmet } from "react-helmet";
import PrivatePage from "../components/pages/PrivatePage";

const Private = () => (
  <div id={"wrapper"}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Sympl</title>
    </Helmet>
    <PrivatePage />
  </div>
);

export default Private;
